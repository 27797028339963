<template>
    <div
        v-if="typeof post.content === 'string'"
        class="gap-4 overflow-hidden bg-white shadow-card md:rounded-xl"
    >
        <div
            class="flex items-center justify-between px-4 pt-4 pb-2 cursor-pointer"
        >
            <div class="flex items-center gap-2">
                <Link :href="route('candidate.profile', post?.user?.id)">
                    <Avatar
                        size="12"
                        :user="post.user"
                        :postOrganization="post?.organization"
                        :preview="true"
                        class="bg-200-blue"
                    />
                </Link>
                <div class="grid">
                    <h2 class="text-base font-semibold text-primary">
                        {{ displayName }}
                    </h2>
                    <p class="text-xs text-disabled">
                        {{ formattedDate(post.created_at) }}
                    </p>
                </div>
            </div>

            <!-- admin actions buttons -->
            <div
                class="relative"
                v-if="
                    ($page?.props?.auth?.user &&
                        $page?.props?.auth?.user?.id === post?.user_id) ||
                    superUser
                "
            >
                <tippy>
                    <Dropdown align="right" width="48">
                        <template #trigger>
                            <GenericButton
                                type="button"
                                theme="inherit-text"
                                class="flex"
                            >
                                <div class="cursor-pointer">
                                    <Icon type="3dots" />
                                </div>
                            </GenericButton>
                        </template>
                        <template #content>
                            <div
                                @click="editPost()"
                                class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out cursor-pointer text-start hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                            >
                                {{ $t("Edit") }}
                            </div>
                            <div
                                @click="confirmDelete(post.id)"
                                class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out cursor-pointer text-start hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                            >
                                {{ $t("Delete Post ") }}
                            </div>

                            <div
                                v-if="post?.media[0]?.type === 'video'"
                                @click="confirmDeleteVideo(post.id)"
                                class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out cursor-pointer text-start hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                            >
                                {{ $t("Delete Video") }}
                            </div>
                        </template>
                    </Dropdown>
                    <template #content>
                        {{ $t("More") }}
                    </template>
                </tippy>
            </div>
        </div>
        <div
            class="flex flex-col gap-2 text-sm whitespace-break-spaces text-primary"
        >
            <template v-for="(part, index) in parsedContent" :key="index">
                <template v-if="part.isLink">
                    <!-- <LinkPreview class="inline-flex" :src="part.text" /> -->
                    <a :href="part.text" target="blank" class="inline-flex"
                        >{{ part.text }}
                    </a>
                </template>
                <template v-else>
                    <div
                        class="px-4 pb-1.5 postText overflow-hidden"
                        v-if="part.text !== 'empty'"
                    >
                        {{
                            showText || part?.text.length <= 220
                                ? part.text
                                : part.text.substring(0, 220)
                        }}<span
                            @click="showText = !showText"
                            v-if="part?.text.length >= 220"
                            class="inline p-0 m-0 font-bold"
                        >
                            {{ !showText ? "- Show more" : "" }}
                        </span>
                    </div>
                </template>
            </template>
        </div>

        <div v-if="post.media && post.media.length > 0">
            <div class="">
                <div v-if="post.media && post.media.length == 1">
                    <div
                        class="flex justify-center h-48 sm:hidden md:flex lg:hidden"
                    >
                        <div
                            v-for="(media, index) in post?.media"
                            :key="index"
                            class="flex-shrink-0 max-h-[65vh] md:max-h-max flex items-center justify-center w-full"
                        >
                            <component
                                :is="mediaType(media)"
                                :src="mediaTypeUrl(media)"
                                :media="media"
                                alt="File Media"
                                loading="lazy"
                                class="object-cover w-full h-full text-center"
                            />
                        </div>
                    </div>
                </div>
                <div v-else class="flex flex-col items-center">
                    <carousel
                        :items-to-show="1"
                        class="overflow-hidden lg:hidden"
                        @slide-start="handleSlideStart"
                    >
                        <slide
                            v-for="(media, index) in post?.media"
                            :key="index"
                            class="max-h-[65vh] flex items-center justify-center lg:max-h-max"
                        >
                            <component
                                :is="mediaType(media)"
                                :src="mediaTypeUrl(media)"
                                :media="media"
                                alt="File Media"
                                :loading="index === 0 ? undefined : 'lazy'"
                                class="object-cover w-full lg:h-full lg:rounded-lg"
                            />
                        </slide>
                        <template #addons>
                            <!-- <navigation /> -->
                            <pagination />
                        </template>
                    </carousel>
                    <!-- <div class="mt-2 text-center">
							  {{ currentSlide + 1 }}/{{ totalSlides }}
						 </div> -->
                </div>
                <div
                    class="hidden gap-2 overflow-x-auto lg:flex lg:gap-2 lg:overflow-x-auto"
                >
                    <div
                        class="relative w-full overflow-hidden shrink-0 lg:flex-1 aspect-video"
                    >
                        <div
                            class="w-full h-full overflow-hidden"
                            :class="{ '_rounded-lg': post?.media.length > 1 }"
                        >
                            <component
                                :is="mediaType(selectedMedia ?? post?.media[0])"
                                :src="
                                    mediaTypeUrl(
                                        selectedMedia ?? post?.media[0]
                                    )
                                "
                                :media="selectedMedia ?? post?.media[0]"
                                alt="Media"
                                loading="lazy"
                                class="object-cover object-center w-full h-full transition-transform transform"
                            />
                        </div>
                    </div>
                    <div
                        v-if="post?.media.length > 1"
                        class="flex w-full h-full gap-2 shrink-0 lg:flex-col lg:w-1/6"
                    >
                        <div
                            v-for="(media, index) in post?.media"
                            :key="index"
                            v-show="media !== (selectedMedia ?? post?.media[0])"
                            class="z-10 w-full overflow-hidden cursor-pointer shrink-0"
                            @click="handleMediaClick(media)"
                        >
                            <component
                                :is="mediaType(media)"
                                :src="mediaTypeUrl(media, true)"
                                :media="media"
                                alt="File Media"
                                loading="lazy"
                                class="object-cover object-center w-full h-full transition-transform transform pointer-events-none hover:scale-105"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class=""></div>
        <div class="flex items-center justify-between px-4 pt-2 pb-1">
            <LikesCounter
                :post="post"
                :initialLikeCounts="likesCount"
                :initialLikeTypes="likeTypes"
            />
            <div
                @click="showComments = !showComments"
                class="text-xs cursor-pointer text-secondary shrink-0"
            >
                <span v-if="post.comments.length > 1">
                    {{ $t(`${post.comments.length}`) }}
                    {{ $t("comments") }}
                </span>
                <span v-else-if="post.comments.length == 1">
                    {{ $t(`${post.comments.length}`) }}
                    {{ $t("comment") }}
                </span>
                <span v-else>
                    {{ $t(`${post.comments.length}`) }}
                    {{ $t("comments") }}
                </span>
            </div>
        </div>
        <div class="h-px mx-4 border-t border-divider"></div>
        <div class="flex items-center justify-between gap-3 h-14">
            <LikeButton
                :post="post"
                :initialLikeCounts="initialLikeCounts"
                :initialLikedUsers="initialLikedUsers"
                :initialUserLikedType="post.userLikedType"
                @updateLikes="handleLikesCount"
            />
            <tippy class="z-10 flex-auto block w-full py-1">
                <div
                    @click="showComments = !showComments"
                    class="flex flex-col items-center justify-center gap-1 text-sm font-bold cursor-pointer lg:gap-2 lg:flex-row"
                >
                    <Icon type="comment" color="#fff" />
                    <span class="text-xs lg:text-sm">{{ $t("Comments") }}</span>
                </div>
                <template #content>
                    {{ $t("Comments") }}
                </template>
            </tippy>
            <tippy
                @click="shareThisPost(post?.id)"
                class="z-10 flex-auto block w-full py-1"
            >
                <div
                    class="flex flex-col items-center justify-center gap-1 text-sm font-bold cursor-pointer lg:gap-2 lg:flex-row"
                >
                    <Icon type="share" color="#212B36" />
                    <span class="text-xs lg:text-sm"> {{ $t("Share") }}</span>
                </div>
                <template #content>
                    {{ $t("Share") }}
                </template>
            </tippy>
            <tippy class="z-10 flex-auto block w-full py-1 pr-4">
                <div
                    @click="savePost = !savePost"
                    :class="[savePost ? 'active' : '']"
                    class="flex flex-col items-center justify-center gap-1 text-sm font-bold cursor-pointer lg:gap-2 lg:flex-row group/icon"
                >
                    <Icon type="save" color="#fff" />
                    <span class="text-xs lg:text-sm"> {{ $t("Save") }}</span>
                </div>
                <template #content>
                    {{ $t("Save") }}
                </template>
            </tippy>
        </div>
        <div v-show="!showComments" v-if="post.comments.length">
            <div
                class="grid px-4 py-3 relative before:content-[''] before:absolute before:block before:h-full before:backdrop-blur-[5px]"
            >
                <Comment
                    v-for="comment of post.comments.slice(0, 1)"
                    :key="comment.id"
                    :comment="comment"
                    :user="post.user"
                />
            </div>
        </div>
        <div v-show="showComments" class="grid gap-3 px-4 pb-6">
            <Comment
                v-for="comment in post.comments"
                :key="comment.id"
                :comment="comment"
                :user="post.user"
            />
            <div class="flex gap-4">
                <Avatar size="10" :user="comment" />
                <div class="relative flex-auto">
                    <input-user-mention
                        v-model="selectedEmoji"
                        input-class="px-4 py-2 border w-full border-[#919EAB33] text-base pr-16 rounded-lg text-primary placeholder:text-disabled"
                        input-placeholder="Write a comment…"
                        @mention="updateMentions"
                        @submit="submitComment"
                    />

                    <div
                        class="absolute flex items-center gap-3 -translate-y-1/2 right-4 top-1/2"
                    >
                        <div
                            @click="submitComment"
                            class="flex items-center justify-center cursor-pointer w-7 h-7"
                        >
                            <Icon
                                type="send"
                                color="#fffffff"
                                :width="18"
                                :height="18"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="gap-4 p-4 overflow-hidden bg-white shadow-card md:rounded-xl"
    >
        <div
            class="flex flex-col items-center justify-between gap-5 xl:flex-row"
        >
            <div v-if="post.content?.media" class="h-full">
                <component
                    :is="mediaType(post.content?.media)"
                    :src="path + mediaTypeUrl(post.content?.media)"
                    alt="Media"
                    class="object-cover object-center w-full h-full transition-transform transform rounded-lg sm:max-w-52 sm:max-h-40 aspect-video hover:scale-105"
                />
            </div>

            <div class="flex justify-between w-full">
                <div class="w-full">
                    <div class="flex flex-col gap-2">
                        <Link
                            as="h2"
                            :href="route('events.show', post?.id)"
                            class="text-lg font-semibold transition-all cursor-pointer text-primary hover:text-main"
                            v-text="post?.content?.title || '-'"
                        ></Link>
                        <div class="flex justify-between">
                            <div class="w-full space-y-1">
                                <!-- <div class="flex items-center">
                                    <Icon
                                        type="candidates"
                                        class="shrink-0"
                                        color="#637381"
                                        :width="24"
                                        :height="24"
                                    />
                                    <div class="ml-2 text-sm">
                                        {{ post?.content?.attendees?.length }}
                                        {{ $t("person going") }}
                                    </div>
                                </div> -->
                                <div class="relative flex items-center group">
                                    <Icon
                                        type="location"
                                        class="shrink-0"
                                        color="#637381"
                                        :width="24"
                                        :height="24"
                                    />
                                    <div class="ml-2 text-sm">
                                        {{
                                            post?.content?.location?.substring(
                                                0,
                                                25
                                            ) || "—"
                                        }}{{
                                            post?.content?.location?.length > 25
                                                ? "..."
                                                : ""
                                        }}
                                        <div
                                            class="absolute z-10 invisible hidden max-w-full px-2 py-1 text-xs font-bold text-center break-words transition-all rounded-md shadow opacity-0 group-hover:visible group-hover:opacity-100 left-8 -top-12 bg-grey-200 text-primary"
                                            :class="{
                                                '!inline-block':
                                                    post?.content?.location
                                                        ?.length >= 25,
                                            }"
                                        >
                                            {{ post?.content?.location }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full space-y-1">
                                <div class="flex items-center">
                                    <Icon
                                        type="start"
                                        class="shrink-0"
                                        color="#637381"
                                        :width="24"
                                        :height="24"
                                    />
                                    <div class="ml-2 text-sm">
                                        {{
                                            formattedDate(
                                                post?.content?.start_time,
                                                "D.MM.yyyy"
                                            )
                                        }}
                                    </div>
                                </div>
                                <div class="flex items-center text-[#637381]">
                                    <Icon
                                        type="w-full"
                                        class="shrink-0"
                                        fill="#637381"
                                        :width="24"
                                        :height="24"
                                    />
                                    <div class="ml-2 text-sm">
                                        {{ $t("Starts at") }}
                                        {{
                                            formattedDate(
                                                post?.content?.start_time,
                                                "LT"
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p
                            class="flex flex-col gap-3 text-sm relative group whitespace-break-spaces text-[#637381]"
                        >
                            <template
                                v-for="(part, index) in parsedContent"
                                :key="index"
                            >
                                <template v-if="part.isLink">
                                    <!-- <LinkPreview
                                        class="inline-flex"
                                        :src="part.text"
                                    /> -->
                                    <a
                                        :href="part.text"
                                        target="blank"
                                        class="inline-flex"
                                        >{{ part.text }}
                                    </a>
                                </template>
                                <template v-else>
                                    {{ part.text.substring(0, 100)
                                    }}{{
                                        part?.text.length >= 100 ? "..." : ""
                                    }}
                                    <div
                                        class="absolute z-10 invisible max-w-full px-2 py-1 text-xs font-bold text-center break-words transition-all rounded-md shadow opacity-0 group-hover:opacity-100 group-hover:visible left-8 -top-12 bg-grey-200 text-primary"
                                        :class="{
                                            '!inline-block':
                                                part.text.length >= 100,
                                        }"
                                    >
                                        {{ part.text }}
                                    </div>
                                </template>
                            </template>
                        </p>
                        <div class="flex gap-2 overflow-auto">
                            <GenericButton
                                v-if="superUser"
                                @click="confirmDelete(post.id)"
                                class="justify-center !py-1 max-w-16 md:max-w-40 w-full"
                                theme="system-contained"
                                type="button"
                                iconPosition="left"
                            >
                                <template #leftIcon>
                                    <span>
                                        <Icon
                                            type="delete"
                                            fill="#fff"
                                            :width="18"
                                            :height="18"
                                        />
                                    </span>
                                </template>
                                <span class="hidden md:inline-block">{{
                                    $t("Delete")
                                }}</span>
                            </GenericButton>
                            <GenericButton
                                @click="shareThisEvent(post.id)"
                                class="justify-center w-full max-w-16 md:max-w-40 !py-1"
                                theme="inherit-outlined"
                                type="button"
                                iconPosition="left"
                            >
                                <template #leftIcon>
                                    <span>
                                        <Icon
                                            type="share"
                                            color="#212B36"
                                            :width="18"
                                            :height="18"
                                        />
                                    </span>
                                </template>
                                <span class="hidden md:inline-block">
                                    {{ $t("Share") }}</span
                                >
                            </GenericButton>

                            <GenericButton
                                v-if="invite && user !== null"
                                @click="inviteUserToEventMethod"
                                class="justify-center !py-1 max-w-40 w-full bg-[#D6E7E8]"
                                style="color: #016A6D"
                                theme="primary-outlined"
                                type="button"
                                :text="$t('Invite')"
                                iconPosition="left"
                            >
                                <template #leftIcon>
                                    <span>
                                        <Icon
                                            type="envelop"
                                            color="#fff"
                                            :width="18"
                                            :height="18"
                                        />
                                    </span>
                                </template>
                            </GenericButton>
                            <GenericButton
                                v-if="
                                    toCheckUserIsAttend ??
                                    toCheckIfTheUserIsEligibleToAttend
                                "
                                class="justify-center w-full bg-[#016A6D] text-white !py-1"
                                theme="primary-contained"
                                type="button"
                                @click="attendThisEvent(post.id)"
                                :text="$t('Attend')"
                                iconPosition="left"
                            >
                                <template #leftIcon>
                                    <span>
                                        <Icon
                                            type="star"
                                            color="#fffffff"
                                            :width="18"
                                            :height="18"
                                        />
                                    </span>
                                </template>
                            </GenericButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <EditPostModal
        :post="post"
        :modalOpen="editModal"
        @update:toggleModal="editPost"
        @update:data="handleData"
    />
    <InviteUserToEventModal
        :eventId="post?.content?.id"
        :postId="post.id"
        :inviteUserToEventBool="inviteUserToEventBool"
        @update:toggleModal="inviteUserToEventMethod"
    />
    <ovn-toast ref="toast"></ovn-toast>
</template>

<script>
import Avatar from "@/Components/Avatar.vue";
import Dropdown from "@/Components/Dropdown.vue";
import DropdownLink from "@/Components/DropdownLink.vue";
import InputUserMention from "@/Components/Form/InputUserMention.vue";
import GenericButton from "@/Components/GenericButton.vue";
import ReelsPlayer from "@/Components/ReelsPlayer.vue";
import OvnToast from "@/Components/Toast.vue";
import VideoPlayer from "@/Components/VideoPlayer.vue";
import Icon from "@/Icons/jobs-icons.vue";
import LikeButton from "@/Pages/Feed/Partials/LikeButton.vue";
import LikesCounter from "@/Pages/Feed/Partials/LikesCounter.vue";
import InviteUserToEventModal from "@/Pages/Groups/Events/InviteUserToEventModal.vue";
import AddTabFeature from "@/Pages/Property/Add/AddTabFeature.vue";
import { Link, router, usePage } from "@inertiajs/vue3";
import Axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Tippy } from "vue-tippy";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Comment from "./Comment.vue";
import EditPostModal from "./EditPostModal.vue";
import LinkPreview from "./LinkPreview.vue";
import VimeoFrame from "./VimeoFrame.vue";
import YoutubeFrame from "./YoutubeFrame.vue";

export default {
    props: {
        post: Object,
        comingFrom: String,
        superUser: {
            type: Boolean,
        },
        invite: {
            type: Boolean,
            default: true,
        },
        postId: {
            type: Number,
            default: 0,
        },
        initialLikeCounts: {
            type: Number,
            default: 0,
        },
        initialLikedUsers: Array,
        initialUserLikedType: String,
    },
    components: {
        LikesCounter,
        LikeButton,
        InputUserMention,
        LinkPreview,
        Comment,
        Avatar,
        Icon,
        DropdownLink,
        Dropdown,
        GenericButton,
        AddTabFeature,
        EditPostModal,
        InviteUserToEventModal,
        OvnToast,
        Tippy,
        Link,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        YoutubeFrame,
        VimeoFrame,
        VideoPlayer,
        ReelsPlayer,
    },
    data() {
        return {
            showText: false,
            selectedMedia: null,
            selectedEmoji: "",
            editModal: false,
            inviteUserToEventBool: false,
            savePost: false,
            likePost: false,
            showComments: false,
            path: import.meta.env.VITE_APP_URL,
            status: this.comingFrom,
            tooltipShow: false,
            currentSlide: 0, // Track the current slide index
            totalSlides: 0, // Track the total number of slides
            user: this.$page?.props?.auth?.user,
            mentions: [],
            likesCount: this.post.likes_count,
            likeTypes: this.post.likeTypes,
        };
    },
    computed: {
        formattedDate() {
            return function (date) {
                if (!date) return "-";
                return moment(new Date(date)).format("hh:mm • DD MMM YYYY");
            };
        },
        filePathsArray() {
            return this.post.media[0].file_path
                ? this.post.media[0].file_path.split("+")
                : [];
        },
        toCheckIfTheUserIsEligibleToAttend() {
            return this.$page?.props?.auth?.user?.id !== this.post?.user_id;
        },
        toCheckUserIsAttend() {
            let check = this.post?.content?.attendees?.filter(
                (attendee) =>
                    attendee.user_id === this.$page?.props?.auth?.user?.id
            );
            return check && check.length > 0 ? false : true;
        },
        parsedContent() {
            const content =
                typeof this.post.content === "string"
                    ? this.post?.content
                    : this.post?.content?.description;
            const urlRegex = /https?:\/\/[^\s]+/g;
            const result = [];

            let lastIndex = 0;
            let match;
            while ((match = urlRegex.exec(content)) !== null) {
                if (match.index > lastIndex) {
                    result.push({
                        isLink: false,
                        text: content.slice(lastIndex, match.index),
                    });
                }
                result.push({
                    isLink: true,
                    text: match[0],
                });
                lastIndex = match.index + match[0].length;
            }

            if (lastIndex < content?.length) {
                result.push({
                    isLink: false,
                    text: content.slice(lastIndex),
                });
            }
            return result;
        },
        displayName() {
            return (
                this.post?.organization?.name ||
                `${this.post.user.firstname} ${this.post.user.lastname}`
            );
        },
    },
    methods: {
        updateMentions(mentions) {
            this.mentions = mentions;
        },
        shareThisEvent(postId) {
            const urlForCopy = route("agendas.events.attend", postId);
            if (this.isMobile()) {
                // Check if Web Share API is available
                if (navigator.share) {
                    navigator
                        .share({
                            title: "Check this out!",
                            url: urlForCopy,
                        })
                        .then(() => {
                            this.$refs.toast.show(
                                "Shared successfully",
                                "success"
                            );
                        })
                        .catch((err) => {
                            console.error("Error: ", err);
                        });
                } else {
                    console.error("Web Share API not supported.");
                }
            } else if (location.protocol === "https:") {
                navigator.clipboard
                    .writeText(urlForCopy)
                    .then(() => {
                        this.$refs.toast.show(
                            "Url Copied To Clipboard",
                            "success"
                        );
                    })
                    .catch((err) => {
                        console.error("Error: ", err);
                    });
            } else {
                console.error("Not an HTTPS connection.");
            }
        },
        handleSlideStart({ slidingToIndex, currentSlideIndex, slidesCount }) {
            this.currentSlide = currentSlideIndex; // Update current slide index
            this.totalSlides = slidesCount; // Update total number of slides
        },
        shareThisPost(post_id) {
            const copiedUrl =
                import.meta.env.VITE_APP_URL +
                `/feed/posts/${post_id}/view/${this.status}`;
            if (this.isMobile()) {
                // Check if Web Share API is available
                if (navigator.share) {
                    navigator
                        .share({
                            title: "Check this out!",
                            url: copiedUrl,
                        })
                        .then(() => {
                            this.$refs.toast.show(
                                "Shared successfully",
                                "success"
                            );
                        })
                        .catch((err) => {
                            console.error("Error: ", err);
                        });
                } else {
                    console.error("Web Share API not supported.");
                }
            } else if (location.protocol === "https:") {
                navigator.clipboard
                    .writeText(copiedUrl)
                    .then(() => {
                        this.$refs.toast.show(
                            "Url Copied To Clipboard",
                            "success"
                        );
                    })
                    .catch((err) => {
                        console.error("Error: ", err);
                    });
            } else {
                console.error("Not an HTTPS connection.");
            }
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        },
        handleMediaClick(media) {
            this.selectedMedia = media;
        },
        mediaType(media) {
            if (media?.type === "image") {
                return "img";
            } else if (media?.type === "video") {
                if (media?.embed) {
                    if (
                        media?.embed.includes("youtube") ||
                        media?.embed.includes("youtu.be")
                    ) {
                        return "YoutubeFrame";
                    } else if (media?.embed.includes("vimeo")) {
                        return "VimeoFrame";
                    } else {
                        if (this.comingFrom === "Reels") {
                            // return "VideoPlayer";
                            return 'ReelsPlayer';
                        }
                        return "VideoPlayer";
                    }
                }
                if (this.comingFrom === "Reels") {
                    // return "VideoPlayer";
                    return 'ReelsPlayer';
                }
                return "VideoPlayer";
            } else {
                if (this.comingFrom === "Reels") {
                    // return "VideoPlayer";
                    return 'ReelsPlayer';
                }
                return "VideoPlayer";
            }
        },

        mediaTypeUrl(media, preview = false) {
            if (media?.url || media?.embed) {
                if (media?.type === "image" && preview && media?.preview) {
                    return media.preview;
                } else if (media?.type === "image" && media?.url_webp) {
                    return media.url_webp;
                }
                return media.url !== "" ? media.url : media.embed;
            } else {
                return (
                    import.meta.env.VITE_APP_URL + "/assets/img/not-found.png"
                );
            }
        },
        handleData(data) {
            return this.$emit("update:data", data);
        },
        submitComment() {
            if (!this.selectedEmoji.trim()) {
                return;
            }

            Axios.post(route("add_comment"), {
                comment: this.selectedEmoji,
                postId: this.post.id,
                mentions: this.mentions,
            })
                .then((response) => {
                    console.log("comment submitted:", response);
                    this.selectedEmoji = "";
                    const newComment = response.data.comments;

                    if (!this.post.comments) {
                        this.post.comments = [];
                    }

                    this.post.comments.push(newComment[0]);
                    // const emit = defineEmits(['update:comments']);
                    // emit('update:comments', post.comments);
                })
                .catch((error) => {
                    console.error("Failed to submit comment:", error);
                });
        },

        handleEmojiSelected(emoji) {
            this.selectedEmoji += emoji;
        },
        editPost() {
            this.editModal = !this.editModal;
        },
        inviteUserToEventMethod() {
            this.inviteUserToEventBool = !this.inviteUserToEventBool;
        },

        confirmDelete(post_id) {
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You will not be able to recover this post!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, keep it",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: this.$t("Deleted!"),
                        text: this.$t("Your post has been deleted."),
                        icon: "success",
                    });
                    this.deleteItem(post_id);
                }
            });
        },

        confirmDeleteVideo(post_id) {
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You will not be able to recover this video!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, keep it",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: this.$t("Deleted!"),
                        text: this.$t("Your video has been deleted."),
                        icon: "success",
                    });
                    this.deleteItem(post_id, true);
                }
            });
        },

        deleteItem(post_id, isVideo = false) {
            const id = { id: post_id, video: isVideo };
            router.post(route("posts.delete"), id, {
                onSuccess: () => {
                    reloadData();
                },
            });
        },
        attendThisEvent(postId) {
            this.$inertia.get(route("agendas.events.attend", postId));
        },
        insertBrEvery30Chars(text) {
            let result = "";
            const chunkSize = 70;
            for (let i = 0; i < text.length; i += chunkSize) {
                result += text.substr(i, chunkSize);
                if (i + chunkSize < text.length) {
                    result += "<br>";
                }
            }
            return result;
        },
        enterTooltip() {
            this.tooltipShow = true;
        },
        leaveTooltip() {
            this.tooltipShow = false;
        },
        handleLikesCount(likesCount, likeTypes) {
            this.likesCount = likesCount;
            this.likeTypes = likeTypes;
        },
    },
};
</script>
<style>
.carousel__pagination {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
}
.triangle {
    --r: 3px; /* border radius */
    width: 8px;
    aspect-ratio: 1 / cos(30deg);
    -webkit-mask: linear-gradient(0deg, #0000 calc(3 * var(--r) / 2), #000 0),
        radial-gradient(
            var(--r) at 50% calc(100% - 2 * var(--r)),
            #000 98%,
            #0000 101%
        );
    clip-path: polygon(50% 100%, 100% 0, 0 0);
    background: #212b3666;
}
</style>
